import { createTheme, responsiveFontSizes } from "@material-ui/core/styles"

let theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#62ffdc",
      main: "#00d4aa",
      dark: "#00a27b",
      contrastText: "#000",
    },
    secondary: {
      light: "#fff562",
      main: "#d8c32c",
      dark: "#a39300",
      contrastText: "#000",
    },
  },
})
theme = responsiveFontSizes(theme)

export default theme
